import {
  Component,
  ElementRef,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

export type PagAction =
  | 'checked_and_approved'
  | 'needs_amendment'
  | 'concerns_risk';

@Component({
  selector: 'app-pag-approval-confirm-modal',
  templateUrl: './pag-approval-confirm-modal.component.html',
  styleUrls: ['./pag-approval-confirm-modal.component.scss'],
})
export class PagApprovalConfirmModalComponent {
  @ViewChild('pagCommentModal', { static: false })
  pagCommentModal: ElementRef;

  @Output() pagData = new EventEmitter<{
    pag_action: PagAction[];
    pag_comment: string;
  }>();

  pagActions: PagAction[] = [];
  pagComment = '';

  constructor(private modalService: NgbModal) {}

  open(actions: PagAction[], comment: string): void {
    if (actions.length === 0) {
      this.pagActions = ['checked_and_approved'];
    } else {
      this.pagActions = actions;
    }
    this.pagComment = comment ?? '';
    this.modalService.open(this.pagCommentModal, {
      backdrop: 'static',
      centered: true,
      size: 'lg',
    });
  }

  onPagChange(pagValue: PagAction): void {
    if (this.pagActions.includes(pagValue)) {
      this.pagActions = this.pagActions.filter(
        (action: PagAction) => action !== pagValue,
      );
    } else {
      this.pagActions.push(pagValue);
    }
  }

  submit(): void {
    const pagData = {
      pag_action: this.pagActions,
      pag_comment: this.pagComment,
    };
    this.pagData.emit(pagData);
  }
}
