<ng-template #pagCommentModal
  let-c="close">
  <app-modal-confirmation [title]="'MEMOS.Suggestion / Comments by PAG'"
    [btnConfirm]="'MEMOS.NEXT'"
    [disableBtn]="pagActions.length === 0"
    (confirmModal)="submit()">
    <ng-template appCustomModalBody>
      <div class="modal-body p-3">
        <div class="d-flex mb-3">
          <div class="form-check mr-5">
            <input class="form-check-input"
                type="checkbox"
                value="checked_and_approved"
                [checked]="pagActions.includes('checked_and_approved')"
                (change)="onPagChange('checked_and_approved')">
            <label class="text-nowrap mt-2">
              {{ 'MEMOS.Checked and Approved' |translate}}
            </label>
          </div>
          <div class="form-check mr-5">
            <input class="form-check-input"
                type="checkbox"
                value="needs_amendment"
                [checked]="pagActions.includes('needs_amendment')"
                (change)="onPagChange('needs_amendment')">
            <label class="text-nowrap mt-2">
              {{ 'MEMOS.Needs Amendment' |translate}}
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input mr-5"
                type="checkbox"
                value="concerns_risk"
                [checked]="pagActions.includes('concerns_risk')"
                (change)="onPagChange('concerns_risk')">
            <label class="text-nowrap mt-2">
              {{ 'MEMOS.Concerns / Risk' |translate}}
            </label>
          </div>
        </div>
        <div>
          <span>{{ 'MEMOS.Comments' | translate }}</span>
          <div class="form-group mt-2">
            <textarea class="form-control"
                [(ngModel)]="pagComment"
                fz-elastic>
            </textarea>
          </div>
        </div>
      </div>
    </ng-template>
  </app-modal-confirmation>
</ng-template>